import React from 'react';
import axios from 'axios';
import LyftCall from '../screens/LyftCall';
import Override from '../screens/Override';
import Status from '../screens/Status';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

let log = false;

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  imgLogo: {
    height: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  keyTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  valTxt: {
    lineHeight: 1.25,
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 500,
    // color: '#2080ff',
  },
  profileIcon: {
    height: '45px !important',
  },
  fab: {
    display: 'block',
    height: 150,
    width: 150,
    margin: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
  },
  fabTxt: {
    marginTop: -16,
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 600,
  },
  btn: {
    marginBottom: 4,
    marginLeft: 8,
    marginRight: 8,
  },
  mapDiv: {
    display: 'block',
    width: '100%',
  },
  map: {
    display: 'block',
    width: '100%',
    maxWidth: 448,
    border: theme.border[0],
    margin: '8px auto',
  },
  title: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
  },
  title2: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.disabled,
  },
  driverNames: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  lineBreak: {
    width: '100%',
    height: 1,
    margin: 8,
    background: '#e0e0e0',
  },
});

class Validation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      override: false,
      drivers: [],
      sharedRideMoves: [],
      activeAttempt: null,
    };
  }

  componentWillMount = async () => {
    await this.updateNestedRideInfo();
  };

  componentWillReceiveProps = async newProps => {
    await this.updateNestedRideInfo();
  };

  // componentWillReceiveProps = () => console.log("will receive props:", this.props)

  updateNestedRideInfo = async () => {
    if (this.props.rideMove) await this.setState({ sharedRideMoves: this.props.rideMove.movesByLyftTriggerId });
    if (this.props.rideMove.ride) {
      if (this.props.rideMove.ride.activeAttempt) {
        await this.setState({ activeAttempt: this.props.rideMove.ride.activeAttempt });
        if (log) console.log('activeAttempt:', this.state.activeAttempt);
      }
    }
    if (log) console.log('sharedRideMoves:', this.state.sharedRideMoves);
    Array.from(this.state.sharedRideMoves || []).forEach(rideMove => {
      if (rideMove.parentMove && rideMove.parentMove.driver_id) {
        this.handleTookanDriverProfile(rideMove.parentMove.driver_id);
      }
    });
  };

  // Retrieve driver's profile picture URL and also get back last known lat/lng coordinates
  handleTookanDriverProfile = driverId => {
    if (this.state.drivers.find(o => o.id === driverId)) return;
    if (log) console.log('handleTookanDriverProfile driver_id:', driverId);
    try {
      axios({
        method: 'POST',
        url: `/.netlify/functions/handleTookanDriverProfile`,
        data: { driverId: driverId },
      }).then(res => {
        // if (log) console.log("driverArr:", drivers)
        // res.data.imgURL ? setDrivers(drivers => [...drivers, { id: driver_id, url: res.data.imgURL }]) : setDrivers(drivers => [...drivers, { id: driver_id, url: "https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png" }])
        if (res.data.imgURL) {
          let newDrivers = this.state.drivers;
          newDrivers.push({
            id: driverId,
            url: res.data.imgURL,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            location_updated: res.data.location_updated,
          });
          this.setState({
            drivers: newDrivers,
          });
        }
      });
    } catch (err) {
      // setDrivers(drivers => [...drivers, { id: driver_id, url: "https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png" }])
      let newDrivers = this.state.drivers;
      newDrivers.push({
        id: driverId,
        url: 'https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png',
      });
      this.setState({
        drivers: newDrivers,
      });
    }
  };

  toggleOverride = () => this.setState({ override: !this.state.override });

  handleValidation = rideMove => {
    var rideType = rideMove && rideMove.ride_type ? rideMove.ride_type : '';

    if (log) console.log('sharedRideMoves:', this.state.sharedRideMoves);
    if (log) console.log('activeAttempt:', this.state.activeAttempt);

    const parseDriverNames = () => {
      // array of driver names from all shared rides
      const driverArr = this.state.sharedRideMoves.map(rideMove => rideMove.parentMove);
      // array to hold names of drivers other than the current driver
      let sharedRiders = [];
      if (log) console.log('driverArr', driverArr);
      driverArr.forEach((driver, index) => {
        if (driver) {
          if (log) console.log('driver:', driver);
          if (log) console.log('driver.driver_name:', driver.driver_name);
          if (log) console.log('rideMove:', rideMove);
          // Skip over names that match the parent move's driver
          if (driver.driver_name === rideMove.driver_name) return;
          // Add driver's first name to sharedRiders array
          else {
            sharedRiders.push({
              name: driver.driver_name.split(' ')[0],
              status: driver.status ? `(${driver.status})` : null,
              id: driver.driver_id,
              // iconUrl: handleTookanDriverProfile(driver.driver_id)
            });
          }
        }
      });
      // Show that the rider is solo if the sharedRiders array is empty
      if (sharedRiders.length < 1)
        return <Typography className={this.props.classes.title2}>No Additional Riders</Typography>;
      // Otherwise show the names of drivers sharing the Lyft ride
      else
        return (
          <>
            <Typography className={this.props.classes.title}>Additional Riders:</Typography>
            {sharedRiders.map(rider => {
              if (log) console.log('rendering driver:', rider);
              // if (log) console.log("this.state.drivers.find(o => o.id === rider.id):", this.state.drivers.find(o => o.id === rider.id).url)
              return (
                <Typography className={this.props.classes.driverNames} key={`driverName-${rider}`}>
                  {' '}
                  <img
                    alt='profile img'
                    className={this.props.classes.profileIcon}
                    src={
                      this.state.drivers.find(o => o.id === rider.id)
                        ? this.state.drivers.find(o => o.id === rider.id).url
                        : ''
                    }
                    key={`driverIcon-${rider}`}
                  />{' '}
                  {rider.name} {rider.status}
                </Typography>
              );
            })}
          </>
        );
    };

    if (log)
      console.log(
        'status validation:',
        this.handleDriverStatusValidation(Array.from(this.state.sharedRideMoves || []))
      );
    // Skip over validation when override prop is present
    if (this.state.override)
      return (
        <LyftCall
          rideMove={rideMove}
          rideType={rideType}
          drivers={this.state.drivers}
          coords={this.props.coords}
          classes={this.props.classes}
          sharedRideMoves={this.state.sharedRideMoves}
          override={this.state.override}
          toggleOverride={this.toggleOverride}
          parseDriverNames={parseDriverNames}
          apolloClient={this.props.apolloClient}
        />
      );
    // Render status page if an existing Lyft ride is present
    else if (this.state.activeAttempt && this.props.rideMove.ride.activeAttempt !== 'canceled') {
      if (log) console.log('activeAttempt exists:', this.state.activeAttempt);
      return (
        <Status
          rideMove={rideMove}
          rideType={rideType}
          drivers={this.state.drivers}
          coords={this.props.coords}
          classes={this.props.classes}
          sharedRideMoves={this.state.sharedRideMoves}
          activeAttempt={this.state.activeAttempt}
          toggleOverride={this.toggleOverride}
          parseDriverNames={parseDriverNames}
          apolloClient={this.props.apolloClient}
        />
      );
    }
    // Render Override page if one or more drivers have not arrived at the delivery location
    else if (this.handleDriverStatusValidation(Array.from(this.state.sharedRideMoves || [])).length > 0)
      return (
        <Override
          rideMove={rideMove}
          rideType={rideType}
          drivers={this.state.drivers}
          coords={this.props.coords}
          classes={this.props.classes}
          parent_move={rideMove.parentMove}
          sharedRideMoves={this.state.sharedRideMoves}
          toggleOverride={this.toggleOverride}
          parseDriverNames={parseDriverNames}
        />
      );
    // Render LyftCall page if a parent move is retrieved and all drivers have arrived
    else
      return (
        <LyftCall
          rideMove={rideMove}
          rideType={rideType}
          drivers={this.state.drivers}
          coords={this.props.coords}
          classes={this.props.classes}
          sharedRideMoves={this.state.sharedRideMoves}
          parseDriverNames={parseDriverNames}
          apolloClient={this.props.apolloClient}
        />
      );
  };

  handleDriverStatusValidation = rideMoves => {
    let driversNotArrived = [];
    // Push an obj with driver name and status to driversNotArrived array if driver has not arrived or completed the delivery
    for (let rideMove of rideMoves) {
      if (
        rideMove.parentMove &&
        rideMove.parentMove.status &&
        (!rideMove.parentMove.status.includes('delivery started') ||
          !rideMove.parentMove.status.includes('delivery arrived') ||
          !rideMove.parentMove.status.includes('delivery successful'))
      ) {
        driversNotArrived.push({ driver: rideMove.parentMove.driver_name, status: rideMove.parentMove.status });
      }
    }
    // Return the array (either empty or with one or more objects)
    return driversNotArrived;
  };

  render() {
    return this.handleValidation(this.props.rideMove);
    // return null
  }
}

export default withStyles(styles)(Validation);
