const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

/** Check if the input is an object and isnt empty */
const isObjWithProps = (input = null) => {
  // Check if the input is an object and isnt empty
  if (!input || typeof input !== `object` || Object.keys(input).length === 0) return false;
  return true;
};

/** Dig into a nested object and avoid type errors
 * (The double equals is necessary)
 * @param {Object} obj - Object with nested fields to dig into
 * @param {String} nestedKey - (Optional) Dot-notation string to dig out of the nested object
 * @returns {any} Returns a type-error free "undefined"
 */
const dig = (obj = null, nestedKey = ``) => {
  if (obj && isObjWithProps(obj) && nestedKey) {
    let value = nestedKey.split(`.`).reduce((o, x) => (o == undefined ? o : o[x]), obj);
    return value;
  }
  return null;
};

export { capFirst, isObjWithProps, dig };
