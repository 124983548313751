import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '85vw',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Failure = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='row' spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>Failed to retrieve ride info. Please contact dispatch:</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h4'>
            <a href={`tel:800-913-7674`}>Call: 800-913-7674</a>
          </Typography>
          <Typography variant='h4'>
            <a href={`sms:804-487-3935`}>Text: 804-487-3935</a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Failure;
