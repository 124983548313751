import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

process.env.REACT_APP_ENV !== 'development' &&
  Sentry.init({
    dsn: 'https://372811068aa94bfcb2733fd758eebdca@o491290.ingest.sentry.io/5556738',
    autoSessionTracking: true,
    environment: process.env.REACT_APP_ENV,
    release: 'returns-portal@v1.2.0',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

ReactDOM.render(<App />, document.getElementById('root'));
