import React from 'react';
import { makeStyles, Grid, Typography, Icon } from '@material-ui/core';

const hdLogo = require('../static/logo-banner-light.svg');

export default function Header(props) {
  const cls = useStyles();
  return (
    <>
      <header className={cls.header}>
        <div className={cls.logo}>
          <img className={cls.logoImg} src={hdLogo} alt='HopDrive' />
        </div>
      </header>

      <footer className={cls.footer}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <a href={`tel:800-913-7674`}>
              <div className={cls.iconBtn}>
                <Icon className={cls.icon}>phone</Icon>
              </div>
            </a>
          </Grid>

          <Grid item xs>
            {/* <Typography>Contact Dispatch</Typography> */}
          </Grid>

          <Grid item>
            <a href={`sms:804-487-3935`}>
              <div className={cls.iconBtn}>
                <Icon className={cls.icon}>sms</Icon>
              </div>
            </a>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  header: {
    zIndex: 200,
    position: 'sticky',
    top: 0,
    width: '100%',
    marginBottom: 16,
    textAlign: 'center',
    background: theme.palette.default.main,
  },
  logo: {
    width: '100%',
  },
  logoImg: {
    maxWidth: 256,
    maxHeight: 40,
    marginTop: 14,
    marginBottom: 4,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  footer: {
    zIndex: 100,
    position: 'fixed',
    bottom: 16,
    left: 16,
    right: 16,
    textAlign: 'center',
  },
  contactTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 400,
  },
  contactNumTxt: {
    marginLeft: 4,
    textDecoration: 'none',
    fontWeight: 500,
  },
  iconBtn: {
    padding: theme.spacing(1.5),
    borderRadius: '50%',
    background: theme.palette.action.hover,
    '&:hover, &:focus': {
      background: theme.palette.action.selected,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  icon: {
    display: 'block',
    fontSize: 28,
    color: theme.palette.text.secondary,
  },
}));
