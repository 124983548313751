import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'relative',
    width: '100%',
    height: theme.spacing(8),
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  return <div className={classes.footer}></div>;
}
