import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import { capFirst } from '../utils/tools';

const Override = props => {
  const rideType = props.rideType ? props.rideType : 'lyft';

  return (
    <div className={props.classes.root}>
      <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Typography className={props.classes.title}>{capFirst(rideType)} Ride Details</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={props.classes.keyTxt}>Lane</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`http://maps.google.com/maps?saddr="${props.rideMove.lane.pickup.address}"&daddr="${props.rideMove.lane.delivery.address}"`}
            >
              {props.rideMove.lane.pickup.name} to {props.rideMove.lane.delivery.name}
            </a>
          </Typography>
        </Grid>

        <div className={props.classes.lineBreak} />

        <Grid item xs={12}>
          <Typography className={props.classes.driverNames}>
            {props.parseDriverNames(Array.from(props.sharedRideMoves || []))}
          </Typography>
        </Grid>

        <div className={props.classes.lineBreak} />

        <Grid item xs={12}>
          Not all drivers have arrived, do you want to continue?
        </Grid>

        <div className={props.classes.lineBreak} />

        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            style={{ backgroundColor: 'green' }}
            className={props.classes.button}
            onClick={() => props.toggleOverride()}
          >
            Yes
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Override;
