import React from 'react';
import axios from 'axios';
import { matchPath } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';

import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import { ApolloProvider } from 'react-apollo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loading from './components/Loading';
import LandingPage from './components/LandingPage';
import { QUERY_RIDE_MOVE_ID } from './utils/gql';

let log = false;
var https = require('https');

const version = require('./version.js');

const theme = createMuiTheme({
  palette: {
    default: {
      main: `#323640`,
      light: `#646c80`,
      lighter: `#a0a8b4`,
      dark: `#16181c`,
      contrastText: `#ffffff`,
    },

    primary: {
      main: `#f44232`,
      light: `#f88876`,
      lighter: `#ffdad8`,
      dark: `#b43220`,
      contrastText: `#ffffff`,
    },
    secondary: {
      main: `#486496`,
      light: `#7696c0`,
      lighter: `#dae8ff`,
      dark: `#2b3d59`,
      contrastText: `#ffffff`,
    },
    tertiary: {
      main: `#509090`,
      light: `#88b4b4`,
      dark: `#406464`,
      contrastText: `#ffffff`,
    },
    quaternary: {
      main: `#ffc050`,
      light: `#ffda99`,
      dark: `#e8a020`,
      contrastText: `#ffffff`,
    },

    info: {
      main: `#2080ff`,
      light: `#64b5f6`,
      dark: `#1976d2`,
      contrastText: `#ffffff`,
    },
    error: {
      main: `#ff2050`,
      lighter: `#ffe8f0`,
      light: `#ffa0a8`,
      dark: `#d41025`,
      contrastText: `#ffffff`,
    },
    warning: {
      main: `#ffa040`,
      light: `#ffb74d`,
      dark: `#f57c00`,
      contrastText: `#ffffff`,
    },
    success: {
      main: `#20c820`,
      lighter: `#d8f8d8`,
      light: `#80c880`,
      dark: `#3a8e3a`,
      contrastText: `#ffffff`,
    },

    ops: {
      main: `#f05020`,
    },
    concierge: {
      main: `#4080c8`,
    },
    lyft: {
      main: `#ea0b8c`,
    },
    uber: {
      main: `#000000`,
    },
    auto: {
      main: `#20c880`,
    },

    text: {
      primary: `#323640`,
      secondary: `#32364096`,
      disabled: `#32364072`,
      hint: `#32364072`,
      contrast: `#ffffff`,
      contrastFade: `#ffffff96`,
    },

    background: {
      paper: `#ffffff`,
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },

    action: {
      hover: `#00000010`,
      selected: `#00000020`,
      focus: `#00000030`,
      active: `#00000080`,
      disabled: `#00000060`,
      disabledBackground: `#00000030`,
    },

    divider: `#00000020`,
    dividerStrong: `#e0e0e0`,
    softDivider: `#32323216`,
    hardDivider: `#32323232`,
    drop: `#32364010`,
    border: `#ddd`,

    fade: [
      `#00000010`,
      `#00000020`,
      `#00000030`,
      `#00000040`,
      `#00000050`,
      `#00000060`,
      `#00000080`,
      `#000000a0`,
      `#000000b4`,
      `#000000c8`,
    ],
  },

  border: [`1px solid #00000020`, `1px solid #00000040`, `1px solid #00000060`],

  shape: {
    borderRadius: `4px`,
    paperRadius: `8px`,
  },

  shadow: {
    none: `none`,
    soft: `0 0 16px #00000016`,
    medium: `0 0 16px #00000024`,
    harsh: `0 0 16px #00000040`,
    nav: `0 0 16px #00000040`,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apolloClient: {},
      setupApollo: token => {
        log && console.log('Setting up Apollo...');
        const authLink = setContext(async () => {
            const clientName = `returns-portal-${version.tag || 'vX.X.X'}`;
            return {
              headers: {
                authorization: `Bearer ${token}`,
                'hasura-client-name': clientName,
              },
            };
          }),
          wsurl = `wss://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
          httpurl = `https://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                const clientName = `returns-portal-${version.tag || 'vX.X.X'}`;
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'hasura-client-name': clientName,
                  },
                };
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query);
              return kind === 'OperationDefinition' && operation === 'subscription';
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          });
        log && console.log('Apollo Client Initialized! ', client);
        this.setState({ apolloClient: client });
      },
    };
  }

  componentDidMount = async () => {
    const paths = matchPath(window.location.pathname, { path: '/:id' });
    if (paths) this.state.id = paths.params.id;
    try {
      await axios
        .post(`https://${process.env.REACT_APP_A0_SD}.auth0.com/oauth/token`, {
          grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
          username: this.state.id,
          password: this.state.id,
          client_id: process.env.REACT_APP_A0_ID,
          realm: process.env.REACT_APP_A0_REALM,
        })
        .then(res => {
          this.state.setupApollo(res.data.id_token);
          try {
            const agent = new https.Agent({ rejectUnauthorized: false });
            axios({
              method: 'POST',
              url: `https://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
              data: { query: QUERY_RIDE_MOVE_ID },
              headers: { authorization: `Bearer ${res.data.id_token}` },
              httpsAgent: agent,
            }).then(res => {
              if (res && res.data && res.data.data && res.data.data.moves && res.data.data.moves.length > 0) {
                const id = res.data.data.moves[0].id;
                log && console.log(`Ride Move ID:`, id);
                this.setState({ rideMoveId: id });
              }
            });
          } catch (err) {
            console.error(`Failed to retreive move ID:`, err);
          }
        });
    } catch (err) {
      console.error('Error granting authorization:', err);
    }
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={this.state.apolloClient}>
          <Router>
            <ToastContainer position='top-center' />
            <CssBaseline />
            {this.state.rideMoveId ? (
              <Route
                exact
                path='/:id/'
                component={() => (
                  <LandingPage rideMoveId={this.state.rideMoveId} apolloClient={this.state.apolloClient} />
                )}
              />
            ) : (
              <Loading />
            )}
          </Router>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
