import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Grid, Typography, Button } from '@material-ui/core';

import { capFirst } from '../utils/tools';

/** Capitalize the first letter of each word */
const capEach = (str = null) => {
  if (str && typeof str === `string`) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
  return str;
};

/** Get formatted status string from a ride */
const getFormattedStatusFromRide = (ride = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for ride
  if (ride) {
    // Set local variables
    fallbackStatus = `Not Called`;
    const status = ride.status ? ride.status.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (status) {
      if (status === `pending`) return `Pending...`;
      if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
        return `Awaiting Response...`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
      if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
      if (status === `canceled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};

const Status = props => {
  const [retry, setRetry] = useState(false);
  const rideType = props.rideType ? props.rideType : 'lyft';
  const apolloClient = props.apolloClient;

  const handleAwaitingResponse = () => {
    setTimeout(() => setRetry(true), 10000);
    return retry ? (
      <Button
        fullWidth
        disableElevation
        className={props.classes.btn}
        variant='contained'
        color='primary'
        onClick={() => handleRetry(props.rideMove.id)}
      >
        No response, retry?
      </Button>
    ) : null;
  };

  const handleRetry = async ride_move_id => {
    setRetry(false);

    let variables = { rideMoveId: ride_move_id, attempt: null };

    apolloClient
      .mutate({
        mutation: gql`
          mutation updateActiveAttempt($rideMoveId: bigint!, $attempt: bigint) {
            update_lyftrides(
              where: { ride_move_id: { _eq: $rideMoveId } }
              _set: { active_attempt: $attempt, updatedat: "now()" }
            ) {
              affected_rows
              returning {
                id
                ride_move_id
                active_attempt
              }
            }
          }
        `,
        variables: variables,
      })
      .then(res => {
        props.toggleOverride();
      });
  };

  return (
    <div className={props.classes.root}>
      <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Typography className={props.classes.title}>{capFirst(rideType)} Ride Details</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={props.classes.keyTxt}>Lane</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`http://maps.google.com/maps?saddr="${props.rideMove.lane.pickup.address}"&daddr="${props.rideMove.lane.delivery.address}"`}
            >
              {props.rideMove.lane.pickup.name} to {props.rideMove.lane.delivery.name}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={props.classes.keyTxt}>Pickup</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`https://maps.google.com/?q="${props.rideMove.lane.pickup.address}"`}
            >
              {props.rideMove.lane.pickup.name}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={props.classes.keyTxt}>Delivery</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`https://maps.google.com/?q="${props.rideMove.lane.delivery.address}"`}
            >
              {props.rideMove.lane.delivery.name}
            </a>
          </Typography>
        </Grid>

        <div className={props.classes.lineBreak} />

        <Grid item xs={12}>
          {props.parseDriverNames(Array.from(props.sharedRideMoves || []))}
        </Grid>

        <div className={props.classes.lineBreak} />

        <Grid item xs={12}>
          <Typography className={props.classes.title}>
            Status - {getFormattedStatusFromRide(props.activeAttempt)}
          </Typography>
        </Grid>

        {props.activeAttempt.status === 'awaitingResponse' ? handleAwaitingResponse() : null}

        {props.activeAttempt.status === 'canceled' || props.activeAttempt.status === 'failed' ? (
          <Button
            fullWidth
            disableElevation
            className={props.classes.btn}
            variant='contained'
            color='primary'
            onClick={() => props.toggleOverride()}
          >
            Retry?
          </Button>
        ) : props.activeAttempt.driver_first_name ? (
          <>
            <Grid item xs={6}>
              <Typography className={props.classes.keyTxt}>Driver</Typography>
              <Typography className={props.classes.valTxt}>{props.activeAttempt.driver_first_name}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={props.classes.keyTxt}>Phone</Typography>
              <Typography className={props.classes.valTxt}>
                <a className={props.classes.valTxt} href={`tel:${props.activeAttempt.driver_phone}`}>
                  {props.activeAttempt.driver_phone}
                </a>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={props.classes.keyTxt}>Vehicle</Typography>
              <Typography className={props.classes.valTxt}>
                {props.activeAttempt.driver_vehicle_color} {props.activeAttempt.driver_vehicle_make}{' '}
                {props.activeAttempt.driver_vehicle_model}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={props.classes.keyTxt}>License</Typography>
              <Typography className={props.classes.valTxt}>
                {props.activeAttempt.driver_vehicle_license_plate}
              </Typography>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default Status;
