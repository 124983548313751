import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Grid, Button, Typography } from '@material-ui/core';

import GoogleStaticMapURL from '../utils/GoogleStaticMapUrl';

const log = false;

const VerifyPickup = props => {
  const [retry, setRetry] = useState(false);
  const [usePickup, setUsePickup] = useState(false);
  const [coords, setCoords] = useState({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    log && console.log('props.coords', props.coords);
    if (!props.coords) getCoords();
  }, []);

  const getCoords = async () => {
    log && console.log('Getting Coordinates...');

    navigator.geolocation
      ? navigator.geolocation.getCurrentPosition(useCoords, errorCoords, {
          maximumAge: 60000,
          timeout: 5000,
          enableHighAccuracy: true,
        })
      : errorCoords();
  };

  const useCoords = pos => {
    log && console.log('Successfully determined your location:', pos);

    toast.success('successfully determined your location');
    setCoords({
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
    });
  };

  const errorCoords = event => {
    log && console.log('Error fetching coordinates', event);
    if (!retry) {
      // give user the opportunity to update their browser location settings...
      toast.warning('No current location coordinates detected.');
      setRetry(true);
    } else {
      // if location settings are still disabled, use parent location
      setCoords({
        latitude: props.pickup.latitude,
        longitude: props.pickup.longitude,
      });
      setUsePickup(true);
      toast.success(`${props.pickup.name} set as pickup location. Confirm location below.`);
    }
  };

  const staticMapUrl = () =>
    GoogleStaticMapURL.build({
      key: process.env.REACT_APP_GSM_KEY,
      markers: [
        {
          icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
          shadow: true,
          lat: coords && coords.latitude ? coords.latitude : props.coords ? props.coords.latitude : '',
          lon: coords && coords.longitude ? coords.longitude : props.coords ? props.coords.longitude : '',
        },
      ],
      center: 'auto',
      zoom: '17',
      scale: false,
      size: '448x248',
      maptype: 'roadmap',
      format: 'png',
      visual_refresh: true,
    });

  return (
    <Grid container justifyContent='center' alignItems='center' direction='row' spacing={1}>
      {/* ------------- COORDINATES DETECTED  ------------- */}
      {(coords.latitude && coords.longitude) || props.coords ? (
        <>
          <Grid item xs={12}>
            <Typography>
              You are outside of the known pickup area, please select your actual pickup location:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              disableElevation
              variant='contained'
              color='primary'
              onClick={() => props.handleLyftCall()}
            >
              {props.pickup.name}
            </Button>
          </Grid>

          {!usePickup ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                variant='contained'
                color='secondary'
                onClick={() =>
                  props.coords ? props.handleValidateLocation(true) : props.handleValidateLocation(true, coords)
                }
              >
                Current Location (Below)
              </Button>
            </Grid>
          ) : null}
        </>
      ) : null}

      {/* --------------- COORDINATES UNDETECTED ---- RETRY --------------- */}
      {retry && !coords.latitude && !coords.longitude && !props.coords && (
        <>
          <Grid item xs={6}>
            <Typography>
              Unable to determine your current location. To use your current coordinates as the pickup location, permit
              current location use in your browser settings.
              <br />
              <br />
              Otherwise, we will set the move pickup location, {props.pickup.name}, as the pickup address.
              <br />
              <br />
            </Typography>
            <Button
              variant='contained'
              size='small'
              className={props.classes.verifyBtn}
              color='secondary'
              onClick={() => getCoords()}
            >
              Get Pickup Coordinates
            </Button>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <div className={props.classes.mapDiv}>
          <img className={props.classes.map} src={staticMapUrl()} alt={`lane-img`} />
        </div>
      </Grid>
    </Grid>
  );
};

export default VerifyPickup;
