import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'fixed',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  );
}
