import React, { useState } from 'react';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { Grid, Typography, Fab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLyft, faUber, faAdn } from '@fortawesome/free-brands-svg-icons';

import { capFirst, dig } from '../utils/tools';
import GetDistance from '../utils/GetDistance';

// import Status from './Status';
import Loading from '../components/Loading';
import VerifyPickup from './VerifyPickup';

let log = false;

const LyftCall = props => {
  const [loading, setLoading] = useState(false);
  const [pickupNeedsVerify, setpickupNeedsVerify] = useState(props.coords ? false : true);

  const rideType = props.rideType;
  const apolloClient = props.apolloClient;
  const icon = rideType === 'lyft' ? faLyft : rideType === 'uber' ? faUber : faAdn;

  const handleValidateLocation = (override = false, coords) => {
    let coordinates = coords ? coords : props.coords;
    let location_type = coords ? 'override coords' : 'database coords';
    let currentLoc = null;

    // Check user's current coordinates against the pickup location
    try {
      log && console.log('Setting location by GPS...');
      if (coordinates) {
        if (coordinates.latitude && coordinates.longitude) {
          currentLoc = [coordinates.latitude, coordinates.longitude];
          location_type = 'phone coords';
        }
      }

      if (!currentLoc) {
        log && console.log("Could not locate based on phone's GPS, using Tookan's last known location...");
        let driver = props.drivers.find(o => o.id === props.rideMove.driver_id);
        // Check if Tookan's last known location is within the tolerance defined in ENV var
        let difInSec = Math.round(Date.now() / 1000) - Math.round(new Date(driver.location_updated).getTime() / 1000);
        if (difInSec <= process.env.REACT_APP_TKN_COORD_BUF) {
          if (log)
            console.log(
              'Location has been updated within the allowed tolerance of',
              process.env.REACT_APP_TKN_COORD_BUF,
              'seconds'
            );
          currentLoc = [driver.latitude, driver.longitude];
          location_type = 'tookan coords';
        } else {
          if (log)
            console.log(
              'Location has not been updated within the allowed tolerance of',
              process.env.REACT_APP_TKN_COORD_BUF,
              'seconds, using the default pickup location coords...'
            );
          currentLoc = [props.rideMove.lane.pickup.latitude, props.rideMove.lane.pickup.longitude];
          location_type = 'database coords';
        }
      }
    } catch (err) {
      if (log) console.log('Failed to get current location');
      console.log(err);
      setpickupNeedsVerify(true);
      return;
    }

    if (override) {
      if (log) console.log(`Override - calling ${capFirst(rideType)} with current location...`);
      handleLyftCall(currentLoc, location_type);
    } else {
      const pickupLoc = [props.rideMove.lane.pickup.latitude, props.rideMove.lane.pickup.longitude];
      const distanceFromPickupLoc = GetDistance(currentLoc, pickupLoc, true);

      if (log) console.log('Current coords:', coordinates);
      if (log) console.log('Pickup location coords:', pickupLoc);
      if (log) console.log('Distance from pickup location:', distanceFromPickupLoc.toFixed(1), 'mi');

      if (distanceFromPickupLoc > Number(process.env.REACT_APP_COORD_DIST)) {
        if (log) console.log('Current location is outside of allowed bounds...');
        if (log)
          console.log(
            'distanceFromPickupLoc:',
            distanceFromPickupLoc,
            'allowed:',
            Number(process.env.REACT_APP_COORD_DIST)
          );
        setpickupNeedsVerify(true);
      } else {
        if (log) console.log('Current location is within allowed bounds...');
        if (log)
          console.log(
            'distanceFromPickupLoc:',
            distanceFromPickupLoc,
            'allowed:',
            Number(process.env.REACT_APP_COORD_DIST)
          );
        handleLyftCall(currentLoc, location_type);
      }
    }
  };

  const handleLyftCall = async (coords = null, location_type = 'database coords') => {
    setpickupNeedsVerify(false);
    setLoading(true);

    try {
      let variables = {
        rideMoveId: props.rideMove.id,
        latitude: coords ? coords[0] : null,
        longitude: coords ? coords[1] : null,
        locationType: location_type,
      };
      log && console.log(`Variables:`, variables);

      // Insert new row into the lyftrides table
      apolloClient
        .mutate({
          mutation: UPSERT_RIDE,
          variables: variables,
        })
        .then(res => {
          log && console.log('handleLyftCall response:', res);
          if (
            dig(res, `data.insert_lyftrides.returning`) &&
            res.data.insert_lyftrides.returning[0] &&
            res.data.insert_lyftrides.returning[0].ride_move_id
          ) {
            if (props.override) props.toggleOverride();
          } else {
            console.error(`Failed to call ride - Ride was not inserted!`);
            toast.error(`Failed to call ride! Please contact a dispatcher and show them this error.`);
            setLoading(false);
          }
        })
        .catch(err => {
          console.error(err);
          toast.error(`Failed to call ride! Please contact a dispatcher and show them this error.`);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  /* ---------------------------------------- Render ---------------------------------------- */
  return (
    <div className={props.classes.root}>
      <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Typography className={props.classes.title}>{capFirst(rideType)} Ride Details</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={props.classes.keyTxt}>Lane</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`http://maps.google.com/maps?saddr="${props.rideMove.lane.pickup.address}"&daddr="${props.rideMove.lane.delivery.address}"`}
            >
              {props.rideMove.lane.pickup.name} to {props.rideMove.lane.delivery.name}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={props.classes.keyTxt}>Pickup</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`https://maps.google.com/?q="${props.rideMove.lane.pickup.address}"`}
            >
              {props.rideMove.lane.pickup.name}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={props.classes.keyTxt}>Delivery</Typography>
          <Typography className={props.classes.valTxt}>
            <a
              className={props.classes.valTxt}
              href={`https://maps.google.com/?q="${props.rideMove.lane.delivery.address}"`}
            >
              {props.rideMove.lane.delivery.name}
            </a>
          </Typography>
        </Grid>

        <div className={props.classes.lineBreak} />

        <Grid item xs={12}>
          {props.parseDriverNames(Array.from(props.sharedRideMoves || []))}
        </Grid>

        <div className={props.classes.lineBreak} />

        {!pickupNeedsVerify ? (
          !loading ? (
            <>
              <Grid item xs={12}>
                <Fab
                  color='primary'
                  style={{ background: rideType === `lyft` ? `#ea0b8c` : rideType === `uber` ? `#000000` : `#20c880` }}
                  aria-label='call'
                  className={props.classes.fab}
                  onClick={() => handleValidateLocation()}
                >
                  <FontAwesomeIcon style={{ transform: 'scale(2.75)', marginTop: '8px' }} icon={icon} />
                </Fab>
              </Grid>

              <Grid item xs={12}>
                <Typography className={props.classes.fabTxt}>Call {capFirst(rideType)} Ride</Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography className={props.classes.title}>Calling ride, please wait...</Typography>
              </Grid>
              <Loading />
            </>
          )
        ) : (
          <Grid item xs={12}>
            <VerifyPickup
              pickup={props.rideMove.lane.pickup}
              pickup_coords={[props.rideMove.lane.pickup.latitude, props.rideMove.lane.pickup.longitude]}
              coords={props.coords}
              classes={props.classes}
              handleLyftCall={handleLyftCall}
              handleValidateLocation={handleValidateLocation}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LyftCall;

/* ---------------------------------------- GQL ---------------------------------------- */
const UPSERT_RIDE = gql`
  mutation upsert_ride($rideMoveId: bigint!, $latitude: numeric, $longitude: numeric, $locationType: String) {
    insert_lyftrides(
      objects: {
        ride_move_id: $rideMoveId
        latitude: $latitude
        longitude: $longitude
        pickup_location_type: $locationType
      }
      on_conflict: { constraint: lyftrides_ride_move_id_key, update_columns: [last_attempt, status] }
    ) {
      affected_rows
      returning {
        id
        ride_move_id
        last_attempt
        latitude
        longitude
        pickup_location_type
      }
    }
  }
`;
