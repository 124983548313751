import gql from 'graphql-tag';

const QUERY_RIDE_MOVE_ID = `
  query rideshare_findRideMoveId {
    moves(where: { move_type: { _eq: "ride" } }) {
      id
    }
  }
`;

const SUBSCRIPTION_RIDE_MOVE = gql`
  subscription rideshare_findRideMove($rideMoveId: bigint) {
    moves(where: { id: { _eq: $rideMoveId }, move_type: { _eq: "ride" } }) {
      id
      driver_id
      driver_name
      lyft_trigger_id
      move_type
      ride_type
      status
      lane {
        id
        description
        pickup {
          id
          name
          address
          latitude
          longitude
        }
        delivery {
          id
          name
          address
          latitude
          longitude
        }
      }
      movesByLyftTriggerId {
        id
        parent_move {
          id
          driver_id
          driver_name
          status
        }
        parentMove {
          id
          driver_id
          driver_name
          status
        }
      }
      parent_move {
        id
        driver_id
        driver_name
        status
      }
      parentMove {
        id
        driver_id
        driver_name
        status
      }
      ride {
        id
        ride_move_id
        activeAttempt {
          id
          ride_id
          ride_type
          status
          driver_phone
          driver_vehicle_color
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_year
          driver_first_name
          driver_rating
          ride_distance
          ride_duration
          createdat
          updatedat
        }
      }
    }
  }
`;

export { QUERY_RIDE_MOVE_ID, SUBSCRIPTION_RIDE_MOVE };
