import React from 'react';
import { Subscription } from 'react-apollo';
import { geolocated } from 'react-geolocated';

import { SUBSCRIPTION_RIDE_MOVE } from '../utils/gql';

import Loading from './Loading';
import Header from './Header';
import Footer from './Footer';
import Validation from './Validation';
import Failure from '../screens/Failure';

let log = false;

function LandingPage(props) {
  return (
    <>
      <Header />
      <Subscription subscription={SUBSCRIPTION_RIDE_MOVE} variables={{ rideMoveId: props.rideMoveId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) {
            console.error(`Failed to fetch ride move:`, error);
            return <Failure />;
          }
          if (data && Array.isArray(data.moves) && data.moves.length > 0) {
            const rideMove = data.moves[0];
            log && console.log(`Subscription response:`, rideMove);
            return <Validation rideMove={rideMove} coords={props.coords} apolloClient={props.apolloClient} />;
          }
          return <Failure />;
        }}
      </Subscription>
      <Footer />
    </>
  );
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(LandingPage);
